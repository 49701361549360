import { cloudinaryAxios } from '../index'

/**
 * It takes an image, uploads it to cloudinary, and returns the response data.
 * @param image - The image file to upload.
 * @returns The image object
 */
const createImage = async image => {
  try {
    image.append('upload_preset', process.env.VUE_APP_PRESET_CLOUDINARY)
    return await cloudinaryAxios.post(`insurance/upload`, image).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  createImage,
}
