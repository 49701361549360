<template>
    <div class="wrapper">
        <div class="card card-custom">
            <div class="card-body p-0">
                <!--begin: Wizard-->
                <div class="wizard wizard-2" id="kt_wizard_v2" data-wizard-state="step-first"
                    data-wizard-clickable="true">
                    <!--begin: Wizard Nav -->
                    <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                        <div class="wizard-steps">
                            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                <div class="wizard-label">
                                    <i class="wizard-icon far fa-user-circle"></i>
                                    <div>
                                        <h3 class="wizard-title">1. PATIENT DETAILS</h3>
                                        <span class="text-muted">Office Visit Info</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-history"></i>
                                    <div>
                                        <h3 class="wizard-title">2. MEDICAL HISTORY</h3>
                                        <span class="text-muted">Subjective</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" href="#" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-th-list"></i>
                                    <div>
                                        <h3 class="wizard-title">3. REVIEW OF SYSTEM</h3>
                                        <span class="text-muted">Subjective</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-heartbeat"></i>
                                    <div>
                                        <h3 class="wizard-title">4. VITAL SIGNS</h3>
                                        <span class="text-muted">Objective</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon far fa-file-alt"></i>
                                    <div>
                                        <h3 class="wizard-title">5. ASSESSMENT</h3>
                                        <span class="text-muted">Objective-Assessment</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-box"></i>
                                    <div>
                                        <h3 class="wizard-title">6. PACKAGES</h3>
                                        <span class="text-muted">Treatments</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-bong"></i>
                                    <div>
                                        <h3 class="wizard-title">7. LABORATORY</h3>
                                        <span class="text-muted">Treatment</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-eye-dropper"></i>
                                    <div>
                                        <h3 class="wizard-title">8. IH INTERVENTIONS</h3>
                                        <span class="text-muted">Treatment</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-crutch"></i>
                                    <div>
                                        <h3 class="wizard-title">9. IH TREATMENTS</h3>
                                        <span class="text-muted">Treatment</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon far fa-file-image"></i>
                                    <div>
                                        <h3 class="wizard-title">10. DIAGNOSTIC IMAGING</h3>
                                        <span class="text-muted">Treatment</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-step" data-wizard-type="step">
                                <div class="wizard-label">
                                    <i class="wizard-icon fas fa-dollar-sign"></i>
                                    <div>
                                        <h3 class="wizard-title">11. CHARGES AND COSTS</h3>
                                        <span class="text-muted">Billing</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Nav -->

                    <!--begin: Wizard Body -->
                    <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                        <!--begin: Wizard Form-->
                        <div class="row">
                            <div class="col-xxl-12">
                                <form class="form" id="kt_form" ref="formNurse">
                                    <!--begin: Wizard Step 1-->
                                    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                        <validation-observer tag="form">
                                            <PatientDetails :detailPatient="detailPatient" :roomOptions="roomOptions" />
                                        </validation-observer>
                                    </div>
                                    <!--end: Wizard Step 1-->

                                    <!--begin: Wizard Step 2-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <MedicalHistory v-if="medicalHistoryConditional"
                                            :medicalHistory="medicalHistory" :allergiesOptions="allergiesOptions"
                                            :ailmentOptions="ailmentOptions" :medicationsOptions="medicationsOptions"
                                            :medicationsPatientOptions="medicationsPatientOptions" />
                                    </div>
                                    <!--end: Wizard Step 2-->

                                    <!--begin: Wizard Step 3-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <ReviewSystem :reviewSystemSelected="reviewSystemSelected"
                                            :reviewSystemLength="reviewSystemLength" />
                                    </div>
                                    <!--end: Wizard Step 3-->

                                    <!--begin: Wizard Step 4-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <VitalSign :vitalSign="vitalSign" :signVitalsOptions="signVitalsOptions" />
                                    </div>
                                    <!--end: Wizard Step 4-->

                                    <!--begin: Wizard Step 5-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <AssessmentProcedure v-if="medicalHistoryConditional"
                                            :medicalHistory="medicalHistory" :assessmentProcedure="assessmentProcedure"
                                            :ailmentOptions="ailmentOptions" :diagnosesOptions="diagnosesOptions" />
                                    </div>
                                    <!--end: Wizard Step 5 -->

                                    <!--begin: Wizard Step 6-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <PackageProcedure v-if="medicalHistoryConditional" :package="package"
                                            :laboratories="laboratories" :interventions="interventions"
                                            :treatments="treatments" :tests="tests" :diagnostics="diagnostics"
                                            :optionsPackage="optionsPackage" @pricePackage="pricePackage($event)" />
                                    </div>
                                    <!--end: Wizard Step 6 -->

                                    <!--begin: Wizard Step 7-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <LaboratoryProcedure :laboratoryPromotion="laboratoryPromotion"
                                            :laboratorySelected="laboratorySelected"
                                            :laboratoryLength="laboratoryLength" :procedurePacks="procedurePacks"
                                            :totalPriceLaboratory="totalPriceLaboratory"
                                            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
                                            :totalPriceInterventions="totalPriceInterventions"
                                            :totalPriceTreatment="totalPriceTreatment" />
                                    </div>
                                    <!--end: Wizard Step 7 -->

                                    <!--begin: Wizard Step 8-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <InterventionsProcedure v-if="medicalHistoryConditional"
                                            :interventionsProcedure="interventionsProcedure" :testOptions="testOptions"
                                            :procedureOptions="procedureOptions"
                                            :totalPriceInterventions="totalPriceInterventions"
                                            :totalPriceLaboratory="totalPriceLaboratory"
                                            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
                                            :totalPriceTreatment="totalPriceTreatment" />
                                    </div>
                                    <!--end: Wizard Step 8 -->

                                    <!--begin: Wizard Step 9-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <TreatmentProcedure v-if="treatmentProcedure.length > 0"
                                            :treatmentProcedure="treatmentProcedure"
                                            :treatmentOptions="treatmentOptions"
                                            :totalPriceTreatment="totalPriceTreatment"
                                            :totalPriceInterventions="totalPriceInterventions"
                                            :totalPriceLaboratory="totalPriceLaboratory"
                                            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging" />
                                    </div>
                                    <!--end: Wizard Step 9 -->

                                    <!--begin: Wizard Step 10-->
                                    <div class="pb-5" data-wizard-type="step-content">
                                        <ImagingProcedure :diagnosticPromotion="diagnosticPromotion"
                                            :diagnosticSelected="diagnosticSelected"
                                            :diagnosticLength="diagnosticLength"
                                            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
                                            :totalPriceTreatment="totalPriceTreatment"
                                            :totalPriceInterventions="totalPriceInterventions"
                                            :totalPriceLaboratory="totalPriceLaboratory" />
                                    </div>
                                    <!--end: Wizard Step 10 -->

                                    <!--begin: Wizard Step 11-->
                                    <div class="pb-5 fin" data-wizard-type="step-content">
                                        <CostProcedure ref="cost"
                                            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
                                            :treatmentProcedure="treatmentProcedure"
                                            :totalPriceLaboratory="totalPriceLaboratory"
                                            :totalPriceInterventions="totalPriceInterventions" :membership="membership"
                                            :totalPriceTreatment="totalPriceTreatment" :getCostProcedure="costProcedure"
                                            :totalPricePackage="totalPricePackage" :detailsPatient="detailPatient"
                                            :laboratorySelected="laboratorySelected" :procedurePacks="procedurePacks"
                                            :interventionsProcedure="interventionsProcedure"
                                            :consultation="consultationPrice" :diagnosticSelected="diagnosticSelected"
                                            :campaign_cupon="detailPatient.campaign_cupon"
                                            @totalAdjustment="totalAdjustment" @is_cupon="getCupon"
                                            @totalPrice="totalPrice" @noKen="noKen">
                                            <div v-if="costProcedure.signature != null">
                                                <img :src="costProcedure.signature" alt="" />
                                            </div>
                                            <div v-else>
                                                <b-form-group label="Signature" label-for="signature">
                                                    <div class="d-flex">
                                                        <VueSignaturePad id="signature" width="100%" height="20vh"
                                                            ref="signaturePad" class="border border-primary rounded"
                                                            :options="{
                                                onBegin: () => {
                                                    $refs.signaturePad.resizeCanvas();
                                                },
                                            }" />
                                                        <div class="align-self-center">
                                                            <b-button
                                                                class="btn-icon align-self-center mx-1 cursor-pointer"
                                                                variant="danger" @click="clearSignature">
                                                                <i class="far fa-trash-alt"></i>
                                                            </b-button>
                                                        </div>
                                                    </div>
                                                </b-form-group>
                                            </div>
                                        </CostProcedure>
                                    </div>
                                    <!--end: Wizard Step 11 -->

                                    <!--begin: Wizard Actions -->
                                    <div class="d-flex justify-content-between border-top pt-10">
                                        <div class="mr-2">
                                            <button
                                                class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-prev">
                                                Previous
                                            </button>
                                        </div>
                                        <div>
                                            <button v-on:click="submit"
                                                class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-submit" :disabled="sbmLoading">
                                                Submit
                                            </button>
                                            <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-next">
                                                Next Step
                                            </button>
                                        </div>
                                    </div>
                                    <!--end: Wizard Actions -->
                                </form>
                            </div>
                            <!--end: Wizard-->
                        </div>
                    </div>
                    <!--end: Wizard Body -->
                </div>
                <ToastNotification ref="toast" />
                <!--end: Wizard-->
                <!-- <checkConsent :dataTreatment="dataTreatment" @closeModalConset="closeConcent($event)" @checkConsent="checkCont($event)"/> -->
            </div>
        </div>
        <panelSteps :totalPriceLaboratory="totalPriceLaboratory"
            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
            :totalPriceInterventions="totalPriceInterventions" :totalPriceTreatment="totalPriceTreatment"
            :totalPricePackage="totalPricePackage" :consultation="consultationPrice"
            :laboratorySelected="laboratorySelected" :membership="membership"
            :interventionsProcedure="interventionsProcedure" :treatmentProcedure="treatmentProcedure"
            :treatmentOptions="treatmentOptions" :diagnosticSelected="diagnosticSelected" :package="package"
            :campaign_cupon="detailPatient.campaign_cupon" class="card2" />
    </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

@media screen and (min-width: 1250px) {
    .card {
        grid-column: 1 / 6;
        grid-row: 1;
    }

    .card2 {
        grid-column: 6 / 6;
        grid-row: 1;
    }

    .wrapper {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { ValidationObserver } from "vee-validate";
import ToastNotification from "@/components/ToastNotification";
import checkConsent from "../recipe/checkConsent.vue";
import PatientDetails from "./components/PatientDetails";
import MedicalHistory from "./components/MedicalHistory";
import ReviewSystem from "./components/ReviewSystem";
import VitalSign from "./components/VitalSign";
import AssessmentProcedure from "./components/AssessmentProcedure";
import PackageProcedure from "./components/PackageProcedure";
import LaboratoryProcedure from "./components/LaboratoryProcedure";
import InterventionsProcedure from "./components/InterventionsProcedure";
import TreatmentProcedure from "./components/TreatmentProcedure";
import ImagingProcedure from "./components/ImagingProcedure";
import CostProcedure from "./components/CostProcedure";
import panelSteps from "./components/panelSteps"

import axiosOV from "@/core/services/apiInsurance/office-visit";
import axiosEC from "@/core/services/apiInsurance/external-settings/cloudinary";
import axiosPA from "@/core/services/apiInsurance/patient";
import axiosAI from "@/core/services/apiInsurance/admin/consults/packages";

export default {
    name: "Wizard-2",
    components: {
        ValidationObserver,
        ToastNotification,
        // checkConsent,
        PatientDetails,
        MedicalHistory,
        ReviewSystem,
        VitalSign,
        AssessmentProcedure,
        PackageProcedure,
        LaboratoryProcedure,
        InterventionsProcedure,
        TreatmentProcedure,
        ImagingProcedure,
        CostProcedure,
        panelSteps
    },
    computed: {
        totalPriceLaboratory() {
            let total = 0;
            const laboratorySelected = this.laboratorySelected;
            for (const key in laboratorySelected) {
                laboratorySelected[key].forEach(
                    (item) => (total += parseInt(item.price))
                );
            }
            const laboratoryPackSelected = this.procedurePacks.laboratoriesPack;
            for (const key of laboratoryPackSelected) {
                total += parseInt(key.price);
            }
            return total;
        },
        totalPriceInterventions() {
            let total = 0;
            const { procedures, tests } = this.interventionsProcedure;
            const totalInterventions = procedures.concat(tests);
            for (const key of totalInterventions) {
                total += parseInt(key.price);
            }
            return total;
        },
        totalPriceTreatment() {
            let total = 0;
            const treatmentProcedure = this.treatmentProcedure;
            for (const key of treatmentProcedure) {
                key.cant && (total += parseInt(key.price) * key.cant);
            }
            return total;
        },
        totalPriceDiagnosticImaging() {
            let total = 0;
            const diagnosticSelected = this.diagnosticSelected;
            for (const key in diagnosticSelected) {
                diagnosticSelected[key].forEach((item) => {
                    return (total += parseInt(item.price));
                });
            }
            return total;
        },
    },
    data() {
        return {
            package: {
                data: null
            },
            optionsPackage: [],
            detailPatient: {
                type_visits_id: 0,
                gender: '',
                name: "",
                lastName: "",
                phone: "",
                dateBirth: null,
                facility: null,
                room: [],
            },
            medicalHistory: {
                ailments: [],
                allergies: [],
                medicationInformation: [],
                current_medications: false,
                userId: null,
                historyId: null,
            },
            vitalSign: [],
            ailmentOptions: {
                data: null,
            },
            allergiesOptions: {
                data: null,
            },
            medicationsOptions: {
                data: null,
            },
            reviewSystemLength: {
                data: null,
            },
            diagnosesOptions: {
                data: null,
            },
            testOptions: {
                data: null,
            },
            procedureOptions: {
                data: null,
            },
            treatmentProcedure: [],
            assessmentProcedure: {
                examination: null,
                describeAbnormal: null,
                describeDiagnoses: null,
                diagnosis: [],
            },
            laboratoryLength: {
                data: null,
            },
            diagnosticLength: {
                data: null,
            },
            medicationsPatientOptions: {
                data: null,
            },
            reviewSystemSelected: {},
            laboratorySelected: {},
            membership: {},
            diagnosticSelected: {},
            laboratoryPromotion: [],
            diagnosticPromotion: [],
            interventionsProcedure: {
                tests: [],
                procedures: [],
            },
            procedurePacks: {
                laboratoriesPack: [],
                checkCategoryLaboratory: {},
            },
            roomOptions: [],
            signVitalsOptions: [],
            treatmentOptions: [],
            loading: false,
            sbmLoading: false,
            conditionalSignature: false,
            totalPriceProcedure: 0,
            costProcedure: {
                signature: null,
                adjusment: 0,
                authorization: false,
                is_cupon: false
            },
            consultationPrice: {},
            medicalHistoryConditional: false,
            wizardForm: null,
            totalPricePackage: 0,
            diagnosesAs: [],
            endWiz: false,
            laboratories: [],
            interventions: [],
            treatments: [],
            diagnostics: [],
            tests: [],
        };
    },
    created() {
        axiosAI.getList().then(({ laboratories, diagnostic_imagings, procedures, treatments, tests }) => {
            this.laboratories = laboratories
            this.diagnostics = diagnostic_imagings
            this.interventions = procedures
            this.treatments = treatments
            this.tests = tests
        })
        axiosAI.getPackages(10).then(({ data: { data } }) => {
            this.package.all = data;
        })
    },
    mounted() {
        this.getDetails();
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Office Visit", route: "../table" },
            { title: "Nurse" },
        ]);

        // Initialize form wizard
        this.wizardForm = new KTWizard("kt_wizard_v2", {
            startStep: 1, // initial active step number
            clickableSteps: true, // allow step clicking
        });

        const formNurse = this.$refs.formNurse;

        // Change event
        this.wizardForm.on("submit", function (wizardObj, formNurse) {
            if (wizardObj.currentStep === 1) {
                /* const isValidated = this.$refs.patientRef.validate()
                console.log(isValidated); */
            }

            setTimeout(() => {
                KTUtil.scrollTop();
            }, 500);
        });
    },
    methods: {
        submit: async function (e) {
            e.preventDefault();

            const checkPhoto = await this.handlePhoto();
            this.sbmLoading = true
            if (checkPhoto) {
                try {
                    const dataOma = await this.createDataOma();
                    await this.checkRoomAvailability(dataOma.room);

                    const { registro } = await this.updatePatientDetails();
                    await this.updateReasonVisit(registro);

                    await this.completeOmaProcedure(dataOma);

                    this.$refs.toast.success("The OMA procedure was completed");
                    this.endWiz = false;
                    this.sbmLoading = false
                    this.$router.go(-1);
                } catch (error) {
                    this.sbmLoading = false
                    this.handleError(error);
                }
            }
            this.sbmLoading = false
        },
        async handlePhoto() {
            if (this.costProcedure.signature) {
                return true;
            } else {
                const savePhoto = this.isSignature();
                if (savePhoto) {
                    const { data } = this.$refs.signaturePad.saveSignature();
                    const photoUrl = await this.uploadPhoto(data);
                    this.costProcedure.signature = photoUrl;
                    return true;
                } else {
                    this.$refs.toast.danger("Signature required");
                    return false;
                }
            }
        },
        async createDataOma() {
            const {
                room,
                finallyReviewSystem,
                finallyLaboratory,
                laboratoriesPack,
                finallyDiagnosticImagings,
                finallyProcedures,
                finallyTest,
                filterTreatment,
                examination,
                describeAbnormal,
                describeDiagnoses,
                diagnosis,
            } = this.createDataObjects();

            return {
                room,
                vital_signs: this.vitalSign,
                review_of_system: finallyReviewSystem,
                laboratories: finallyLaboratory,
                laboratories_pack: this.createFinalArray(laboratoriesPack),
                diagnostic_imagings: finallyDiagnosticImagings,
                procedures: finallyProcedures,
                tests: finallyTest,
                examination,
                describe_abnormal: describeAbnormal,
                describe_diagnoses: describeDiagnoses,
                diagnosis,
                treatments: filterTreatment,
                history: { ...this.medicalHistory },
                signature: this.costProcedure.signature,
                total: parseInt(this.totalPriceProcedure),
                ajustment: this.costProcedure.adjusment,
                is_cupon: this.costProcedure.is_cupon,
                authorization: this.costProcedure.authorization,
                package_id: this.package.data != null ? this.package.data : null,
            };
        },
        getCupon(isCupon) {
            this.costProcedure.is_cupon = isCupon
        },
        async checkRoomAvailability(room) {
            const res = await axiosOV.roomsCheckAvailable(this.$route.params.id, typeof room != 'number' ? room[0] : room);
            if (res.status === 'ERROR') {
                throw new Error(res.message);
            }
        },
        async updatePatientDetails() {
            const { registro } = await axiosPA.patientDetail(this.medicalHistory.userId);
            registro.gender = this.detailPatient.gender;
            return axiosPA.patientUpdate(this.medicalHistory.userId, registro);
        },
        async updateReasonVisit(registro) {
            const resp = await axiosOV.updateReasonVisit(this.detailPatient.appointmentsId, { reason_visit: this.detailPatient.reason_visit });
            if (resp.status !== 'OK') {
                throw new Error("Check the gender of the patient");
            }
        },
        async completeOmaProcedure(dataOma) {
            const data = await axiosOV.procedureOma(this.$route.params.id, dataOma);
            if (data.status === 'ERROR') {
                throw new Error(data.message);
            }
        },
        handleError(error) {
            console.error(error);
            this.$refs.toast.danger(error.message);
        },
        createDataObjects() {
            return {
                room: this.detailPatient.room,
                finallyReviewSystem: this.createFinalCategories(this.reviewSystemSelected),
                finallyLaboratory: this.createFinalCategories(this.laboratorySelected, true),
                laboratoriesPack: this.procedurePacks.laboratoriesPack,
                finallyDiagnosticImagings: this.createFinalCategories(this.diagnosticSelected, true),
                finallyProcedures: this.createFinalArray(this.interventionsProcedure.procedures),
                finallyTest: this.createFinalArray(this.interventionsProcedure.tests),
                filterTreatment: this.treatmentProcedure.filter(item => item.cant != null),
                examination: this.assessmentProcedure.examination,
                describeAbnormal: this.assessmentProcedure.describeAbnormal,
                describeDiagnoses: this.assessmentProcedure.describeDiagnoses,
                diagnosis: this.assessmentProcedure.diagnosis,
            };
        },
        createFinalCategories(selected, includeTrue = false) {
            return includeTrue ? this.createFinalArray(selected) : selected;
        },
        createFinalArray(data) {
            return data ? [...data] : [];
        },
        noKen(withConcent) {
            if (!withConcent) this.treatmentProcedure[this.treatmentProcedure.findIndex((treatment) => treatment.id == 7)].cant = null
        },
        async getDetails() {
            this.loading = true;
            axiosOV
                .dataForOMA(this.$route.params.id)
                .then(async ({ all, office_visit, paginates, rooms, membership }) => {
                    if (office_visit.visit_statuses_id == 5 || office_visit.visit_statuses_id == 6) return this.$router.go(-1);
                    const { check, message } = await this.omaProcedure(this.$route.params.id);
                    if (check != true) {
                        Swal.fire({
                            title: "Error!",
                            text: message,
                            icon: "error",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.$router.go(-1);
                    }
                    const {
                        type_vital_sign,
                        review_of_system: CategoryReviewSystem,
                        category_lab: CategoryLaboratory,
                        treatments,
                        sonograms: CategoryDiagnosticImaging,
                    } = all;
                    this.membership = membership

                    this.signVitalsOptions = type_vital_sign;
                    this.vitalSign = this.signVitalsOptions.map((item) => {
                        return {
                            id: item.id,
                            value: null,
                        };
                    });

                    this.roomOptions = rooms.map((room) => {
                        return {
                            id: room,
                            name: room,
                        };
                    });

                    const {
                        appointments: {
                            patients,
                            facilities,
                            ajustment,
                            consult_prices,
                            campaign_cupon,
                            reason_visit,
                            id,
                            type_visits_id
                        },
                        room,
                        review_of_system,
                        type_vital_sign: VitalSignUser,
                        examination,
                        describe_abnormal,
                        describe_diagnoses,
                        diagnosis,
                        laboratories,
                        laboratories_pack,
                        diagnostic_imagings,
                        package: pack,
                        tests: testUser,
                        procedures: procedureUser,
                        treatments: treatmentUser,
                        signature,
                        authorization,
                    } = office_visit;
                    const rum = []
                    room != undefined || room != null ? rum.push(room) : ''
                    this.detailPatient = {
                        type_visits_id,
                        appointmentsId: id,
                        reason_visit: reason_visit,
                        campaign_cupon: campaign_cupon,
                        gender: patients.gender,
                        name: patients.name,
                        lastName: patients.lastname,
                        phone: patients.phone,
                        dateBirth: patients.date_birth,
                        facility: facilities.name,
                        room: rum
                    };

                    const { histories } = patients;
                    this.medicalHistory = {
                        ailments: histories.ailment.map((item) => item.id),
                        allergies: histories.allergies.map((item) => item.id),
                        medicationInformation: histories.medication_informations,
                        current_medications: histories.current_medications,
                        userId: histories.users_id,
                        historyId: histories.id,
                    };

                    VitalSignUser.forEach((elem, i) => {
                        this.vitalSign[i].value = elem.pivot.value;
                    });

                    const {
                        ailments,
                        allergies,
                        medication,
                        diagnoses,
                        tests,
                        procedure,
                        medication_patient,
                    } = paginates;

                    this.ailmentOptions.data = ailments.data;
                    this.allergiesOptions.data = allergies.data;
                    this.medicationsOptions.data = medication.data;
                    this.diagnosesOptions.data = diagnoses.data;
                    this.testOptions.data = tests.data;
                    this.procedureOptions.data = procedure.data;
                    this.medicationsPatientOptions.data = medication_patient.original.registro.data;

                    this.medicalHistoryConditional = true;

                    let laboratoriesObject = {};

                    this.reviewSystemSelected = this.createInicialCategories(
                        review_of_system,
                        "category"
                    );

                    this.reviewSystemLength.data = CategoryReviewSystem;

                    this.assessmentProcedure = {
                        examination,
                        describeAbnormal: describe_abnormal,
                        describeDiagnoses: describe_diagnoses,
                        diagnosis,
                    };

                    let laboratoriesUser = laboratories;

                    if (
                        laboratories.some((item) => item.pivot.promotion)
                    ) {
                        const { promitions, array } = this.clearPromotions(
                            laboratories,
                            CategoryLaboratory,
                            "laboratories"
                        );
                        laboratoriesUser = array;
                        this.laboratoryPromotion = promitions;
                    }

                    laboratoriesUser.forEach((x) => {
                        if (!laboratoriesObject.hasOwnProperty(x.categories[0].name)) {
                            laboratoriesObject[x.categories[0].name] = [];
                        }
                        laboratoriesObject[x.categories[0].name].push(x);
                    });
                    for (const laboratory of laboratories_pack) {
                        const id = laboratory.office_visitable_id;
                        this.procedurePacks.checkCategoryLaboratory[id] = true;
                        this.procedurePacks.laboratoriesPack.push({ id, ...laboratory });
                    }

                    this.laboratorySelected = laboratoriesObject;
                    const catLaboratories = CategoryLaboratory.map(
                        ({ laboratories, ...res }) => {
                            const laboratoriesM = laboratories.filter(
                                (laboratory) => laboratory.price != null
                            );
                            return {
                                laboratoriesM,
                                ...res,
                            };
                        }
                    );
                    this.laboratoryLength.data = catLaboratories;

                    this.interventionsProcedure = {
                        tests: testUser.map((item) => {
                            let id = item.office_visitable_id;
                            return {
                                id,
                                ...item,
                            };
                        }),
                        procedures: procedureUser.map((item) => {
                            let id = item.office_visitable_id;
                            return {
                                id,
                                ...item,
                            };
                        }),
                    };

                    this.treatmentOptions = this.checkPromotion(
                        treatments,
                        treatmentUser
                    );

                    this.treatmentProcedure = this.treatmentOptions.map((item) => {
                        return {
                            id: item.id,
                            cant: null,
                            price: item.price,
                            options: [],
                        };
                    });

                    this.treatmentProcedure.forEach((iterator, index) => {
                        treatmentUser.forEach((elem) => {
                            if (iterator.id === elem.office_visitable_id) {
                                this.treatmentProcedure[index].cant = elem.pivot.cant;
                            }
                        });
                    });

                    let diagnosticUser = diagnostic_imagings;
                    if (
                        diagnostic_imagings.some((item) => item.pivot.promotion)
                    ) {
                        const { promitions, array } = this.clearPromotions(
                            diagnostic_imagings,
                            CategoryDiagnosticImaging,
                            "diagnostic_imagings"
                        );
                        diagnosticUser = array;
                        this.diagnosticPromotion = promitions;
                    }
                    this.diagnosticSelected = this.createInicialCategories(
                        diagnosticUser,
                        "category_diagnostic_imagings"
                    );

                    const catImaging = CategoryDiagnosticImaging.map(
                        ({ diagnostic_imagings, ...res }) => {
                            const diagnosticM = diagnostic_imagings.filter(
                                (laboratory) => laboratory.price != null
                            );
                            return {
                                diagnosticM,
                                ...res,
                            };
                        }
                    );
                    this.diagnosticLength.data = catImaging;

                    this.costProcedure = {
                        signature,
                        adjusment: ajustment === null ? 0 : ajustment,
                        authorization,
                    };
                    this.consultationPrice = consult_prices;

                    this.loading = false;
                    if (pack) {
                        pack.label = pack.name
                        this.package.all = [pack]
                        this.package.data = pack ? pack.id : null
                        this.$emit('getPackageData')
                    }
                })
                .catch((error) => {
                    this.$router.go(-1);
                });

        },
        async omaProcedure(id) {
            const data = {
                office_visits_id: id,
            };
            const { status, message } = await axiosOV.assignToOma(data);
            if (status === "OK") {
                return { check: true };
            } else {
                return { check: false, message };
            }
        },
        createFinalCategories(arrayBackend, categories = false) {
            const finallyArrayCategories = [];
            for (const key in arrayBackend) {
                if (Object.hasOwnProperty.call(arrayBackend, key)) {
                    if (categories) {
                        finallyArrayCategories.push(
                            ...arrayBackend[key].map((item) => item.id)
                        );
                    } else {
                        finallyArrayCategories.push(...arrayBackend[key]);
                    }
                }
            }
            return finallyArrayCategories;
        },
        createFinalArray(arrayBackend) {
            const arrayNormal = [];
            for (const iterator of arrayBackend) {
                arrayNormal.push(iterator.id || iterator.historyble_id);
            }
            return arrayNormal;
        },
        createInicialCategories(arrayBackend, nameIterator) {
            const categoriesInicialTemporal = {};
            arrayBackend.forEach((x) => {
                if (!categoriesInicialTemporal.hasOwnProperty(x[nameIterator].name)) {
                    categoriesInicialTemporal[x[nameIterator].name] = [];
                }
                if (nameIterator === "category_diagnostic_imagings") {
                    let id = x.office_visitable_id;
                    categoriesInicialTemporal[x[nameIterator].name].push({ ...x, id });
                } else {
                    categoriesInicialTemporal[x[nameIterator].name].push(
                        x.assessmentable_id
                    );
                }
            });
            return categoriesInicialTemporal;
        },
        checkPromotion(treatmentsOptions, treatmentUser) {
            const treatmentFinal = treatmentsOptions.map((item) => {
                if (treatmentUser.filter((itemFilter) => itemFilter.office_visitable_id === item.id).length) {
                    return { ...item, promotion: true };
                } else {
                    return { ...item, promotion: false };
                }
            });
            return treatmentFinal;
        },
        totalAdjustment(value) {
            this.costProcedure.adjusment = value;
        },
        totalPrice(value) {
            if (value > 550) {
                // this.$refs.cost.trueOverrun()
            }
            this.totalPriceProcedure = value;
        },
        pricePackage(value) {
            this.totalPricePackage = value;
        },
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        isSignature() {
            const { isEmpty } = this.$refs.signaturePad.saveSignature();
            return !isEmpty;
        },
        async uploadPhoto(file) {
            const formData = new FormData();
            formData.append("file", file);
            const { secure_url } = await axiosEC.createImage(formData);
            return secure_url;
        },
        validationFormPatient() {
            return new Promise((resolve, reject) => {
                this.$refs.patientRef.validate().then((success) => {
                    if (success) {
                        resolve(true);
                    } else {
                        reject();
                        wizardObj.stop();
                    }
                });
            });
        },
    },
};
</script>
